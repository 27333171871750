<div class="container">
    <div class="form-group">
        <h2>Seleccione la plantilla</h2>
    </div>
    <div class="row" *ngIf="is_loading">
        <h4>Cargando templates...</h4>
    </div>
    <div class="row" *ngIf="!is_loading">
        <div class="col-12 col-md-12">
            <div class="form-group">
                <label for="template">Plantilla</label>
                <ng-select (change)="change_template($event)" [items]="list_of_templates"
                    class="js-example-basic-multiple" placeholder="" bindValue="id" bindLabel="name"
                    [(ngModel)]="template.id">
                </ng-select>
            </div>
        </div>
        <div class="col-12" *ngIf="template.id > 0">
            <h6>Contenido de la plantilla: </h6>

            <div class="pantalla">
                <div class="col-12 whatsapp-bubble">
                    <textarea class="form-control" disabled>{{htmlContent}}</textarea>
                    <p class="hora">4:20 PM</p>
                </div>
            </div>

        </div>
    </div>
    <div class="form-group buttons" *ngIf="!is_loading">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary"
            (click)="onSubmit()">Seleccionar</button>
        <button [attr.disabled]="is_loading? true:null" class="btn btn-secondary" (click)="onCancel()">Cerrar</button>
    </div>
</div>