
<div class="pantalla">



    <div class="col-12 whatsapp-bubble me">
        <textarea class="form-control" disabled>{{html}}</textarea>
        <p class="hora">4:20 PM</p>
    </div>



</div>

