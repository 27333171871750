import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { lastValueFrom } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reporte-consumo',
  templateUrl: './reporte-consumo.component.html',
  styleUrls: ['./reporte-consumo.component.scss']
})
export class ReporteConsumoComponent implements OnInit {
  protected campaigns = [
     {
       id: 1,
       name: 'HONDA 4W - HONDA REMARKETING BBDD 1 14.09.23',
       motivo: 'Motivo 1',
       fecha: '2023-01-01 12:15:00',
       created_by: 'Alessandra Lay',
       cantidad_audiencia: 10456,
       status: 'Enviado',
       status_class: 'badge-success'
     },
     {
       id: 2,
       name: 'HONDA 4W - HONDA RECOMPRA 14.09.23',
       motivo: 'Motivo 1',
       fecha: '2023-12-01 12:15:00',
       created_by: 'Fiorella Velita',
       cantidad_audiencia: 9458,
       status: 'Programado',
       status_class: 'badge-secondary'
     },
     {
       id: 3,
       name: 'HONDA 4W - HONDA REFERIDOS 14.09.23',
       motivo: 'Motivo 1',
       fecha: '2023-01-01 12:15:00',
       created_by: 'Alessandra Lay',
       cantidad_audiencia: 16159,
       status: 'Enviado',
       status_class: 'badge-success'
     },
  ]

  // ARRAY CON FILTROS
  filtros


  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private campaignService: CampaignService,
    private http: HttpClient,
  ) {   this.filtros = {}

  if (localStorage.getItem("campaigns_filtros")) {
    this.filtros = JSON.parse(localStorage.getItem("campaigns_filtros"))
  }

  this.default_values = {}


}




async ngOnInit() {
  this.load_campaigns()
  this.load_values()
}

rerender(): void {


  localStorage.setItem("campaigns_filtros", JSON.stringify(this.filtros))


  this.buttonFiltrarTexto = `Cargando...`
  this.load_campaigns();

  this.buttonFiltrarTexto = "Filtrar"

}


async load_values() {

  const res = <any>await this.campaignService.campaign_service_init_general();

  if (res.success) {

    this.default_values.type = res.payload.type
    this.default_values.tipo_filtro = res.payload.tipo_filtro
  }

  this.buttonFiltrarTexto = "Filtrar"


}

async load_campaigns() {
  Swal.showLoading()


  Swal.close()

}
}