import { Component, OnInit } from '@angular/core';
import { AudienceService } from '../shared/services/audience.service';

import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-loyalty-campaigns-audience',
  templateUrl: './loyalty-campaigns-audience.component.html',
  styleUrls: ['./loyalty-campaigns-audience.component.scss']
})
export class LoyaltyCampaignsAudienceComponent implements OnInit {

  protected audiences = []

  audiencia_type = 'Email'

  constructor(
    protected audienceService: AudienceService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {

    this.audiencia_type = 'Email';

    this.route.url.subscribe(segments => {
      const currentPath = segments.join('/');
      if (currentPath.includes('audiencias-whatsapp')) {
        this.audiencia_type = 'Whatsapp';

        this.loadAudiencesType("Whatsapp")
      } else if (currentPath.includes('sms')) {
        
        this.loadAudiencesType("SMS")
        this.audiencia_type = 'SMS';

      } else if (currentPath.includes('audiencias')) {
        this.loadAudiencesType("Email")
        this.audiencia_type = 'Email';

      }
    })

  }


  async loadAudiencesType(type) {
    const res = <any>await this.audienceService.get_all_audiences_by_type_disponibles(type)
    if (res.success) {
      this.audiences = res.audiences
      console.log(this.audiences)
    }
  }

  async loadAudiencesMarketing(type) {
    const res = <any>await this.audienceService.get_all_audiences_by_type_disponibles(type)
    if (res.success) {
      this.audiences = res.audiences
      console.log(this.audiences)
    }
  }

}