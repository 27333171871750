import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { lastValueFrom } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DecimalPipe } from '@angular/common';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns',
  templateUrl: './loyalty-campaigns.component.html',
  styleUrls: ['./loyalty-campaigns.component.scss'],
  providers: [DecimalPipe]

})
export class LoyaltyCampaignsComponent implements OnInit {

  protected campaigns = [
    // {
    //   id: 1,
    //   name: 'HONDA 4W - HONDA REMARKETING BBDD 1 14.09.23',
    //   motivo: 'Motivo 1',
    //   fecha: '2023-01-01 12:15:00',
    //   created_by: 'Alessandra Lay',
    //   cantidad_audiencia: 10456,
    //   status: 'Enviado',
    //   status_class: 'badge-success'
    // },
    // {
    //   id: 2,
    //   name: 'HONDA 4W - HONDA RECOMPRA 14.09.23',
    //   motivo: 'Motivo 1',
    //   fecha: '2023-12-01 12:15:00',
    //   created_by: 'Fiorella Velita',
    //   cantidad_audiencia: 9458,
    //   status: 'Programado',
    //   status_class: 'badge-secondary'
    // },
    // {
    //   id: 3,
    //   name: 'HONDA 4W - HONDA REFERIDOS 14.09.23',
    //   motivo: 'Motivo 1',
    //   fecha: '2023-01-01 12:15:00',
    //   created_by: 'Alessandra Lay',
    //   cantidad_audiencia: 16159,
    //   status: 'Enviado',
    //   status_class: 'badge-success'
    // },
  ]

  // ARRAY CON FILTROS
  filtros

  saldo
  saldo_mes
  costo_total
  moneda

  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private campaignService: CampaignService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe
  ) { 

    this.filtros = {}

    if (localStorage.getItem("campaigns_email_filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("campaigns_email_filtros"))
    }
  
    this.default_values = {}


  }

  

  
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2')!;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0')!;
  }

  detallado_campaign = {
    invitaciones: 0,
    planning:0,
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0
  }

  detallado_envio = {
    invitaciones: 0,
    planning:0,
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    total: 0,
    otros: 0,
  }
  
  
  async ngOnInit() {

    this.establecerMesAnioActual();
    this.setearFechas();


    this.load_values();
    this.load_campaigns();


  }

  fechaMin: string;
  fechaMax: string;

  establecerMesAnioActual() {
    const hoy = new Date();
    const anio = hoy.getFullYear();
    const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Formato 'YYYY-MM'
    this.filtros.mesAnio = `${anio}-${mes}`;
  }

  setearFechas() {
    const mesAnio = this.filtros.mesAnio;
    if (mesAnio) {
      const [anio, mes] = mesAnio.split('-').map(Number);
      const primerDiaMes = new Date(anio, mes - 1, 1);
      const ultimoDiaMes = new Date(anio, mes, 0);
      
      // Obtener el año y mes actual
      const fechaActual = new Date();
      const anioActual = fechaActual.getFullYear();
      const mesActual = fechaActual.getMonth() + 1; // getMonth() devuelve el mes en base 0
  
      this.filtros.fecha_inicio = primerDiaMes.toISOString().split('T')[0];
  
      if (anio === anioActual && mes === mesActual) {
        // Si es el mes y año actual, usar la fecha actual
        this.filtros.fecha_fin = fechaActual.toISOString().split('T')[0];
      } else {
        // De lo contrario, usar el último día del mes
        this.filtros.fecha_fin = ultimoDiaMes.toISOString().split('T')[0];
      }
  
      this.filtros.fecha_lanzamiento_inicio = '';
      this.filtros.fecha_lanzamiento_fin = '';
  
      // Actualizar los campos de fecha con el rango del mes seleccionado
      this.fechaMin = this.filtros.fecha_inicio;
      this.fechaMax = this.filtros.fecha_fin;
  
      this.updateDateInputs();
    }
  }
  

  updateCalendar() {
    this.setearFechas();
  }

  updateDateInputs() {
    const inputs = document.querySelectorAll('input[type="date"]');
    inputs.forEach(input => {
      (input as HTMLInputElement).setAttribute('min', this.fechaMin);
      (input as HTMLInputElement).setAttribute('max', this.fechaMax);
    });
  }
  
  rerender(): void {


    localStorage.setItem("campaigns_email_filtros", JSON.stringify(this.filtros))


    this.buttonFiltrarTexto = `Cargando...`
    this.load_campaigns();

    this.buttonFiltrarTexto = "Filtrar"

  }


  async load_values() {

    const res = <any>await this.campaignService.campaign_service_init_general();

    if (res.success) {

      this.default_values.type = res.payload.type
      this.default_values.tipo_filtro = res.payload.tipo_filtro
    }

    this.buttonFiltrarTexto = "Filtrar"


  }

  
   editarSaldo() {
    Swal.fire({
      title: 'Editar Saldo',
      input: 'number',
      inputLabel: 'Ingrese el nuevo saldo',
      inputValue: this.saldo_mes,
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Debe ingresar un número válido';
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const saldo = parseFloat(result.value);
        const mesAnio = this.filtros.mesAnio;

        if (this.guardar_saldo(saldo, mesAnio, "email")) {
          Swal.fire('Actualizado!', '', 'success');
          this.load_campaigns();

        }

      }
    });
  }

  async guardar_saldo (saldo, mesAnio, type){
    const res =  <any>await this.campaignService.insert_saldo_mes(saldo, mesAnio, type);
    if (res.success) {
      return true;
    } else {
      return false;
    }
  }



  async load_campaigns() {
    Swal.showLoading();

    const res = <any>await this.campaignService.get_campaign_filter(this.filtros, "Email");

    if (res.success) {
      this.campaigns = res.campaigns;

      this.costo_total = parseFloat(res.costo_total).toFixed(2) ;

      const response_saldo = <any>await this.campaignService.campaign_service_saldo_by_filter(this.filtros, "Email");

      if (response_saldo.success) {
        this.moneda = response_saldo.moneda;

        this.saldo_mes = response_saldo.saldo_mes;
        var calculo_saldo = this.saldo_mes - this.costo_total;
        this.saldo = calculo_saldo.toFixed(2) 
      }
      
      


      this.detallado_campaign = {
        invitaciones:res.detallado_leads.invitaciones  ? res.detallado_leads.invitaciones  : 0 ,
        planning:res.detallado_leads.planning  ? res.detallado_leads.planning  : 0 ,

        remarketing: res.detallado_leads.remarketing  ? res.detallado_leads.remarketing  : 0 ,
        recompra: res.detallado_leads.recompra ? res.detallado_leads.recompra  : 0 ,
        referido: res.detallado_leads.referidos ? res.detallado_leads.referidos  : 0 ,
        encuesta: res.detallado_leads.encuesta ? res.detallado_leads.encuesta  : 0 ,
        bienvenida: res.detallado_leads.bienvenida ? res.detallado_leads.bienvenida  : 0 ,
        total: res.detallado_leads.total ? res.detallado_leads.total  : 0 ,
        otros: res.detallado_leads.otro ? res.detallado_leads.otro  : 0 
      }

      this.detallado_envio = {
        invitaciones:res.detallado_envios.invitaciones  ? res.detallado_envios.invitaciones  : 0 ,
        planning:res.detallado_envios.planning  ? res.detallado_envios.planning  : 0 ,
        
        remarketing: res.detallado_envios.remarketing  ? res.detallado_envios.remarketing  : 0 ,
        recompra: res.detallado_envios.recompra ? res.detallado_envios.recompra  : 0 ,
        referido: res.detallado_envios.referidos ? res.detallado_envios.referidos  : 0 ,
        encuesta: res.detallado_envios.encuesta ? res.detallado_envios.encuesta  : 0 ,
        bienvenida: res.detallado_envios.bienvenida ? res.detallado_envios.bienvenida  : 0 ,
        total: res.detallado_envios.total ? res.detallado_envios.total  : 0 ,
        otros: res.detallado_envios.otro ? res.detallado_envios.otro  : 0 ,
      }

    }
    Swal.close()
  }

}
