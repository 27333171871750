import { HttpHeaders } from "@angular/common/http";
export const environment = {
  production: true,
  firebase: {
    apiKey: "Your Api Key",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"
  },
  // url_api: `http://localhost:8888/mayu-4w-php/`,
  url_api: `https://honda4w-ci.mayu.digital/`,
  node_api: `http://localhost:3001/`,
  // url_api: `http://localhost:8888/mayu-4w-php/`,
  // node_api: `https://honda4w-node.mayu.digital/`,
  options_headers: {
    'headers': new HttpHeaders
  }
};
