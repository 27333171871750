import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-preview-template-loyalty-whatsapp',
  templateUrl: './modal-preview-template-loyalty-whatsapp.component.html',
  styleUrls: ['./modal-preview-template-loyalty-whatsapp.component.scss']
})
export class ModalPreviewTemplateLoyaltyWhatsappComponent implements OnInit {

  @Input() public html_sent

  html

  constructor() {
  }

  ngOnInit() {
    this.html = this.html_sent
    console.log(this.html)
  }

}

