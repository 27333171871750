<app-breadcrumb [title]="'Generar audiencia desde un archivo excel'"
    [items]="['Campañas', 'Audiencias','Generar']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5><u>Audiencia:</u></h5>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Nombre de la audiencia</label>
                            <div class="col-sm-9">
                                <input class="form-control" [(ngModel)]="audiencia.name" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tipo de audiencia</label>
                            <div class="col-sm-9">
                                <ng-select [items]="audienciaType" class="js-example-basic-multiple" placeholder=""
                                    (change)="reset()" [(ngModel)]="audiencia.type">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <div class="row dropzone-custom" *ngIf="rows.length == 0">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>SUBA AQUÍ SU ARCHIVO DE EXCEL:</h5>
                            </div>
                            <div class="card-body">
                                <ngx-dropzone (change)="onSelect($event)" [multiple]="false" *ngIf="!file">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="icon-cloud-up"></i>
                                            <h6>Arrastre aquí su archivo</h6>
                                        </div>
                                    </ngx-dropzone-label>
                                </ngx-dropzone>

                                <progress [hidden]="!file && rows.length === 0" [value]="uploadProgress" max="100"> {{
                                    uploadProgress }}% </progress>
                                <ngx-datatable *ngIf="rows.length > 0" [rows]="rows" [columns]="columns">
                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" *ngIf="rows.length > 0">
                    <h5 class="m-b-10">*Se están mostrando los primeros {{rows_to_show.length}} registros del
                        excel importado. <small><b><u>(Total: {{rows.length}} registros)</u></b></small></h5>

                        <div class="table-responsive mb-5" *ngIf="audiencia.type == 'Genérico'">
                            <table class="table table-dinamico">
                                <thead>
                                    <tr>
                                        <th>Columna</th>
                                        <th>Cabecera</th>
                                        <th>Campo seleccionado</th>
                                        <th>Validar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cabecera of cabeceras; let i = index">
                                        <td>{{ getColumnName(i) }}</td>
                                        <td>{{ cabecera }}</td>
                                        
                                        <td>
                                            <select class="js-example-basic-multiple"
                                                    (change)="onSelectOption(cabecera, $event, i)"
                                                    [(ngModel)]="selectedOptions[i]">
                                                    <option value="" [selected]="!selectedOptions[i]"> - </option>
                                                    <option *ngFor="let option of getAvailableOptions(i)"
                                                            [value]="option.name"
                                                            [selected]="selectedOptions[i] === option.name">
                                                        {{ option.texto }}
                                                    </option>
                                            </select>
                                        </td>
                                          
                                        <td>
                                            <input type="checkbox" (change)="onToggleValidation(i)" [checked]="validations[i]" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="table-responsive mb-5" *ngIf="habilito_dealer_generico">
                            <table class="table table-dinamico">
                              <thead>
                                <tr>
                                  <th>Dealer</th>
                                  <th>Seleccione Verdadero Dealer</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let dealer_generico of dealer_genericos; let i = index">
                                  <td>{{ dealer_generico }}</td>
                                  <td>
                                    <select class="js-example-basic-multiple" (change)="onSelectChange(dealer_generico, $event.target.value)">
                                      <option value="">Seleccione valor</option>
                                      <option *ngFor="let dealer of dealers" [value]="dealer.id">{{ dealer.name }}</option>
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        
                        
                        <div class="table-responsive">
                            <table class="table table-concesionarios">
                                <thead>
                                    <tr>
                                        <th *ngFor="let cabecera of cabeceras" class="text-center" scope="col">{{cabecera}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row_to_show of rows_to_show">
                                        <td class="text-center" *ngFor="let cabecera of cabeceras">{{row_to_show[cabecera]}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>

                <button (click)="generate_audience()" class="btn btn-primary">¡Generar audiencia!</button>
            </div>
        </div>
    </div>
</div>
<br>