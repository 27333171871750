import { Component, Input, OnInit } from '@angular/core';
import { TemplateService } from '../shared/services/template.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-template-edit-modal-whatsapp',
  templateUrl: './template-edit-modal-whatsapp.component.html',
  styleUrls: ['./template-edit-modal-whatsapp.component.scss']
})
export class TemplateEditModalWhatsappComponent implements OnInit {

  @Input('template_id') public template_id;

  template = {
    name: '',
    es_dinamica: '',
    html: ``
  }

  htmlContent = ''
  is_loading = true

  mergeVariables = []

  constructor(
    private templateService: TemplateService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.get_template_by_id()
  }

  onFileSelected($event) {

  }

  async get_template_by_id() {
    if (this.template_id) {
      const res = <any>await this.templateService.get_template_whatsapp_by_id(this.template_id)
      this.is_loading = false
      if (res.success) {
        this.template = res.template

        this.mergeVariables = this.extractMergeVariables(this.template.html); // Extraer y almacenar las variables



      }
    }

  }

  extractMergeVariables(htmlContent: string): string[] {
    const mergeRegex = /#MERGE_\w+/g;
    const matches = htmlContent.match(mergeRegex);
    return matches ? Array.from(new Set(matches)) : [];
  }

  onSubmit() {
  }

  onCancel() {
    this.activeModal.close({
      'success': false
    })
  }


}
