import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatoPersonal } from '../interfaces/nps/dato_personal.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nps-edit-dato-personal-modal',
  templateUrl: './nps-edit-dato-personal-modal.component.html',
  styleUrls: ['./nps-edit-dato-personal-modal.component.scss']
})
export class NpsEditDatoPersonalModalComponent implements OnInit {
  @Input() data: any;
  @Output() formularioEnviado = new EventEmitter<DatoPersonal>();


  indexEdit: BigInt
  dato_personal: DatoPersonal = {
    name: '',
    required: false,
    type: null,
    minlength: null,
    maxlength: null,
    options: []
  };

  tipo_pregunta = [
    { id: 1, name: 'Texto' },
    { id: 2, name: 'Texto Grande' },
    { id: 3, name: 'Seleccionable (select)' },
    { id: 4, name: 'Seleccionable (radio)' }
  ];

  is_loading = false;
  dato_persona: any;
  nombre_opcion = '';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log('DATA = ', this.data)
    this.dato_personal = this.data.datoPersonal
    this.indexEdit = this.data.index
  }

  onCancel() {
    this.activeModal.dismiss('cancel');
  }
  onTipoPreguntaChange() { }

  onSubmit(): void {
    console.log('submit edit')
    if (this.validarFormulario()) {
      console.log('Dato Personal:', this.dato_personal);
      this.formularioEnviado.emit(this.dato_personal);
      this.activeModal.close(this.dato_personal);
    }
  }

  agregarOpcion(): void {
    if (this.nombre_opcion.length) {
      this.dato_personal.options.push({ name: this.nombre_opcion });
      this.nombre_opcion = '';
    } else {
      Swal.fire('Debe ingresar la opción', '', 'error');
    }
  }


  onChangeTipoPregunta(): void {
    if ([1, 2].includes(this.dato_personal.type)) {
      this.dato_personal.options = [];
    }
  }

  eliminarOpcion(i: number): void {
    if (i >= 0 && i < this.dato_personal.options.length) {
      this.dato_personal.options.splice(i, 1);
    } else {
      Swal.fire('Índice fuera de rango', '', 'error');
    }
  }

  private validarFormulario(): boolean {
    if (this.dato_personal.name.length == 0) {
      Swal.fire('Ingrese el nombre del dato personal', '', 'error')
      return false
    }

    if (!this.dato_personal.type) {
      Swal.fire('Seleccione el tipo de pregunta', '', 'error')
      return false
    }

    if ([3, 4].includes(this.dato_personal.type)) {
      if (this.dato_personal.options.length < 2) {
        Swal.fire('Debe ingresar por lo menos 2 opciones', '', 'error')
        return false

      }
    }
    return true
  }
}
